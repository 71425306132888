import React from 'react';
import './App.css';
import Navigation from './Navigation';

function App() {
  return (
    <div>
      <Navigation />
      <header>
        <div className="overlay"></div>
        <video playsInline="playsinline" autoPlay="autoplay" loop="">
          <source src="/images/bg.mp4" type="video/mp4" />
        </video>
        <div className="container h-100">
          <div className="d-flex h-100 align-items-center">
            <div className="container mt-5">
              <div className="row">
                <div className="col">
                  <div className="w-100 text-center text-color">
                    <h1 className="display-5">Software and Testing Consultants</h1>
                  </div>
                </div>
              </div>
              <div className="row mt-5 mb-5">
                <div className="col">
                  <div className="card-deck">
                    <div className="card bg-transparent text-color text-justify border-0">
                      <div className="card-body">
                        <h5 className="card-title text-center">Software Testing Consultancy</h5>
                        <p className="card-text  text-center">We help our customers deliver quality software faster, more effectively and more frequently.
                        Our Consultants has a high motivation and efficient technical and managerial abilities to meet and supply the software testing and business and analytical demands in the market.
                        <br/>We can support you with any software testing needs allowing you to concentrate on your core development activities while we handle the validation work independently.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
      <div className="text-center mt-5"><h2>Services & Products</h2></div>

      <div className="container mb-5">
        <div className="row">
          <div className="col">
            <div className="card-deck mt-5">
              <div className="card">
                <img className="card-img-top img-fluid" src="/images/web-banner.jpg" alt="sepici"></img>
                <div className="card-body">
                  <h5 className="card-title">Web site development and SEO</h5>
                  <p className="card-text">Built for small businesses, sole traders, and freelancers. Create your first web site and e-commerce web site with us.</p>
                </div>
                <div className="card-footer bg-white border-0 text-muted">
                  <a href="mailto:info@rodosto.co.uk?subject = Website Development" class="btn btn-primary">Get in touch</a>
                </div>
              </div>
              <div className="card">
                <img className="card-img-top img-fluid" src="/images/test-banner.jpg" alt="bycore"></img>
                <div className="card-body">
                  <h5 className="card-title">Automated software testing</h5>
                  <p className="card-text">With our agile software testing services there’s no need to run the risk of expensive downtime and performance issues – our expert consultancy will help you develop software testing strategies and quality control procedures that identify potential vulnerabilities in your process, highlight opportunities for efficiency or saving, and minimise risk.</p>
                </div>
                <div className="card-footer bg-white border-0 text-muted">
                  <a href="mailto:info@rodosto.co.uk?subject = Automated Testing" class="btn btn-primary">Get in touch</a>
                </div>
              </div>
              <div className="card">
                <img className="card-img-top img-fluid" src="/images/consultancy-banner.jpg" alt="consultancy"></img>
                <div className="card-body">
                  <h5 className="card-title">Consultancy</h5>
                  <p className="card-text">Are you ready to start building your modern digital business?</p>
                </div>
                <div className="card-footer bg-white border-0 text-muted">
                <a href="mailto:info@rodosto.co.uk?subject = consultancy" class="btn btn-primary">Get in touch</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <footer className="text-muted bg-light p-4 mt-5">
        <div className="container">
          <ul className="list-unstyled float-right">
            <li>info@rodosto.co.uk</li>
          </ul>
          <p className="m-0 p-0">Rodosto Software Ltd. is a limited company registered in England. Company number 12065800</p>
          <p>278 Langham Road, London, United Kingdom, N15 3NP</p>
          <p className="m-0 p-0">&copy; Copyright 2019. Rodosto Software Ltd</p>
        </div>
      </footer>
    </div>

  );
}

export default App;
